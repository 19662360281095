import { type SEOHandle } from '@nasa-gcn/remix-seo'
import { Link, Outlet } from '@remix-run/react'
import { buttonVariants } from '#app/components/ui/button.tsx'
import { Logo } from '#app/components/ui/logo.tsx'
import { UserDropdown } from '#app/components/user-dropdown.tsx'
import { cn } from '#app/utils/misc.tsx'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

export default function Layout() {
	return (
		<>
			<div className="flex h-screen flex-col justify-between">
				<header className="container py-6">
					<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
						<Logo singleLine />
						<div className="flex items-center gap-10">
							<Link
								to="/hotels/list"
								prefetch="intent"
								className={cn(
									buttonVariants({
										variant: 'link',
										size: 'link',
									}),
									'hidden h-8 no-underline md:flex',
								)}
							>
								List your hotel
							</Link>
							<Link
								to="/reservation"
								prefetch="intent"
								className={cn(
									buttonVariants({
										variant: 'link',
										size: 'link',
									}),
									'hidden h-8 no-underline md:flex',
								)}
							>
								Find your reservation
							</Link>
							<UserDropdown />
						</div>
					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>
			</div>
		</>
	)
}
